import React from 'react'
import { useNavigate } from 'react-router-dom'
import { cardStyle } from '../styles/cardStye'

import {
  Typography,
  Avatar,
  AvatarGroup,
  Box,
  CardMedia,
  CardContent,
  Card,
} from '@mui/material/'

export const ProjectCard = ({ project }) => {
  const imageUrl = project?.image_url
    ? typeof project?.image_url === 'string'
      ? project?.image_url
      : URL.createObjectURL(project?.image_url)
    : ''

  const navigate = useNavigate()
  let maxAvatars = Math.min(Math.round(project.memberCount / 4) || 9, 9)

  return (
    <Card
      className='shadow-animate'
      sx={cardStyle(project.color, project.backer)}
    >
      <CardMedia
        component='img'
        image={imageUrl || project?.image_url}
        sx={{
          maxHeight: '197px',
          maxWidth: '350px',
          objectFit: 'contain',
        }}
      />
      <CardContent
        sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
      >
        <Typography
          component='div'
          onClick={() => navigate(`/project/${project.id}`)}
          sx={{
            '&:hover': {
              cursor: 'pointer',
              opacity: [0.9, 0.8, 0.7],
              textDecoration: 'underline',
            },
          }}
          textAlign={'center'}
          variant='h4'
        >
          {project.name}
        </Typography>

        <Typography align={'center'} color='text.secondary' variant='overline'>
          version {project?.version}
        </Typography>

        <Typography color='text.secondary' variant='body2'>
          {project?.description}
        </Typography>

        <Box marginTop={'auto'} sx={{ pt: 1 }}>
          <AvatarGroup max={maxAvatars}>
            {project.members.map((el, index) => {
              return (
                <Avatar
                  alt={el.username}
                  key={`${index}-${JSON.stringify(el.username)}`}
                  src={`https://cdn.discordapp.com/avatars/${el.discord_id}/${el.avatar}.png`}
                />
              )
            })}
          </AvatarGroup>
        </Box>
      </CardContent>
    </Card>
  )
}
