import axios from 'axios'
import { dispatchAlert } from './store'
import { parseJwt } from './utils'
import { put } from '@vercel/blob'

export const vercelBlob = async (projId, blob) => {
  const request = await put(projId, blob, {
    access: 'public',
    contentType: 'image/*',
    token: 'vercel_blob_rw_xpGAPhXBtWCIDjCx_6xguk0IgWR6X802eF7xoSyoaxMmW6y',
  })

  return request
}

export const requests = async (method, url, options) => {
  if (!options) {
    options = {}
  }

  if (!options.headers) {
    options.headers = {}
  }

  if (!options.data) {
    options.data = {}
  }

  let jwt = localStorage.getItem('jwt')

  // if (!jwt) {
  //   window.location = '/'
  // }

  if (jwt) {
    if (Date.now() >= parseJwt(jwt).exp * 1000) {
      dispatchAlert({
        duration: 5000,
        message: 'Session expired! Please log in again',
        type: 'error',
      })
      localStorage.removeItem('jwt')
      // setTimeout(() => {
      //   window.location = '/'
      // }, 5000)
    } else {
      return axios({
        data: options.data,
        headers: {
          // TODO add authorization header
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json',
          ...options.headers,
        },
        method: method,
        url: url,
      })
        .then((res) => {
          if (res.status >= 200 && res.status < 400 && options.alert) {
            dispatchAlert({
              message: options.alertMessage || 'Success',
              type: 'success',
            })
          } else if (res.status >= 400) {
            throw res
          }

          return res.data
        })
        .catch((error) => {
          if (error?.response.status === 403 || error.status === 403) {
            dispatchAlert({
              message: 'Forbidden',
              type: 'error',
            })
          } else {
            throw error
          }
        })
    }
  }
}
