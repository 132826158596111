import React from 'react'
import { Box, Modal, Typography, Divider } from '@mui/material'

const style = {
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
}

export const CreditModal = ({ handleClose, open }) => {
  return (
    <Modal
      aria-describedby='modal-modal-description'
      aria-labelledby='modal-modal-title'
      onClose={handleClose}
      open={open}
    >
      <Box sx={style}>
        <Typography component='h2' id='modal-modal-title' variant='h6'>
          Credited Assets
        </Typography>
        <Typography id='modal-modal-description' sx={{ mt: 2 }}>
          Default Card icon by{' '}
          <a href='https://mtmrem.tumblr.com/'>https://mtmrem.tumblr.com/</a>
          <Divider sx={{ m: 3 }} />
          Hollow Knight and Silksong Icons from Team Cherry Press Kit
        </Typography>
      </Box>
    </Modal>
  )
}
