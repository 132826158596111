import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { TabPanel } from '../components/TabPanel'

import { ProjectsContext, UserContext } from '../context'

import { ProjectMembers } from '../components/EditProject/ProjectMembers'
import { ProjectWebhooks } from '../components/EditProject/ProjectWebhooks'
import { ProjectInfo } from '../components/EditProject/ProjectInfo.jsx'

import { Tab, Grid, Tabs, Divider } from '@mui/material'

export const EditProject = () => {
  const { project } = useContext(ProjectsContext)
  const { user } = useContext(UserContext)
  const params = useParams()
  const navigate = useNavigate()

  const [tabValue, setTabValue] = useState(0)

  useEffect(() => {
    if (project && user.discord_id !== project.owner) {
      navigate('/')
    }
  }, [params.projectId, project])

  const handleTabChange = (_, tab) => {
    setTabValue(tab)
  }

  return (
    <div>
      <Grid container>
        <Grid item lg={12} sx={{ mt: 5 }}>
          <Tabs onChange={handleTabChange} value={tabValue} variant='fullWidth'>
            <Tab id='manage-webhooks' label='Webhooks' />
            <Tab id='manage-members' label='Members' />
            <Tab id='manage-info' label='Info' />
          </Tabs>
          <Divider sx={{ mb: 2, mt: 2 }} />
          <TabPanel index={0} value={tabValue}>
            <ProjectWebhooks />
          </TabPanel>
          <TabPanel index={1} value={tabValue}>
            <ProjectMembers />
          </TabPanel>
          <TabPanel index={2} value={tabValue}>
            <ProjectInfo />
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  )
}
