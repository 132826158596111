import React, { useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import * as api from '~/api'
import {
  Button,
  FormControlLabel,
  TextField,
  Grid,
  Checkbox,
  Divider,
} from '@mui/material'
import { dispatchAlert } from '../../store'
import { ProjectsContext } from '../../context'
import { ProjectCard } from '../ProjectCard'

export const ProjectInfo = () => {
  const params = useParams()
  const [blob, setBlob] = useState()
  const [imageButtonColor, setImageButtonColor] = useState('primary')

  const [imageButtonText, setImageButtonText] = useState('Upload Project Image')
  const [saveProjBtnColor, setSaveProjBtnColor] = useState('primary')
  const [saveProjBtnText, setSaveProjBtnText] = useState('Save Project Changes')

  const { project, setProject } = useContext(ProjectsContext)

  const saveProject = () => {
    if (blob) {
      api.vercelBlob(project.id, blob).then((data) => {
        api
          .requests('put', `/api/project/${params.projectId}`, {
            alert: true,
            alertMessage: `Successfully updated"${project.name}`,
            data: {
              description: project.description,
              image_url: data.url,
              is_public: project.is_public,
              name: project.name,
              version: project.version,
            },
            project,
          })
          .then((data) => {
            setProject((prevProject) => {
              const updatedProject = {
                ...prevProject,
                description: data.description,
                image_url: data.image_url,
                is_public: data.is_public,
                name: data.name,
                version: data.version,
              }
              return updatedProject
            })
            setBlob('')
            setSaveProjBtnColor('success')
            setSaveProjBtnText('Success!')
          })
      })
    } else {
      api
        .requests('put', `/api/project/${params.projectId}`, {
          alert: true,
          alertMessage: `Successfully updated"${project.name}`,
          data: {
            description: project.description,
            is_public: project.is_public,
            name: project.name,
            version: project.version,
          },
          project,
        })
        .then((data) => {
          setProject(data)
          setSaveProjBtnColor('success')
          setSaveProjBtnText('Success!')
        })
    }
  }

  const blobHandler = (e) => {
    const file = e.target.files[0]
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        setImageButtonColor('error')
        setImageButtonText('Too Large!')
        setTimeout(() => {
          setImageButtonColor('primary')
          setImageButtonText('Upload Project Image')
        }, 5000)
        dispatchAlert({
          message: 'Image must be less than 2MB',
          type: 'error',
        })
      } else {
        setImageButtonColor('success')
        setImageButtonText('Success!')
        const image = new Blob([file], { type: file.type })
        setBlob(file)

        setProject((prevProject) => {
          const updatedProject = { ...prevProject, image_url: image }
          return updatedProject
        })
      }
    }
  }

  const updateProject = (field, value) => {
    if (field === 'version') {
      setProject((prevProject) => {
        const updatedProject = { ...prevProject, version: value }
        return updatedProject
      })
    } else if (field === 'description') {
      setProject((prevProject) => {
        const updatedProject = { ...prevProject, description: value }
        return updatedProject
      })
    } else if (field === 'name') {
      setProject((prevProject) => {
        const updatedProject = { ...prevProject, name: value }
        return updatedProject
      })
    } else if (field === 'is_public') {
      setProject((prevProject) => {
        const updatedProject = { ...prevProject, is_public: value }
        return updatedProject
      })
    }
  }

  return (
    <div>
      <Grid
        alignItems='flex-start'
        container
        direction='row'
        justifyContent='space-evenly'
      >
        <Grid item lg={5}>
          <Grid
            alignItems='center'
            container
            direction='column'
            justifyContent='center'
          >
            <Grid item>
              <h2>How your project will appear to others</h2>
            </Grid>
            <Grid item>
              <ProjectCard project={project} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={5}>
          <Grid
            alignItems='center'
            container
            direction='column'
            justifyContent='space-evenly'
          >
            <Grid item sx={{ pb: 5 }}>
              <TextField
                label='Project Name'
                onChange={(e) => updateProject('name', e.target.value)}
                value={project.name}
              />
            </Grid>
            <Grid item sx={{ pb: 5 }}>
              <TextField
                label='Project Description'
                multiline
                onChange={(e) => updateProject('description', e.target.value)}
                rows={4}
                value={project.description}
              />
            </Grid>
            <Grid item sx={{ pb: 5 }}>
              <TextField
                label='Project Version'
                onChange={(e) => updateProject('version', e.target.value)}
                value={project.version}
              />
            </Grid>
            <Grid item sx={{ pb: 5 }}>
              <Button
                color={imageButtonColor}
                component='label'
                sx={{ mr: 4 }}
                variant='contained'
              >
                {imageButtonText}
                <input
                  accept='image/*'
                  hidden
                  onChange={blobHandler}
                  type='file'
                />
              </Button>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={project.is_public}
                    onChange={() =>
                      updateProject('is_public', !project.is_public)
                    }
                  />
                }
                label='Public Project'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid
        alignItems='flex-start'
        container
        direction='row'
        justifyContent='center'
      >
        <Button
          color={saveProjBtnColor}
          onClick={() => saveProject()}
          sx={{ mt: 3 }}
          variant='contained'
        >
          {saveProjBtnText}
        </Button>
      </Grid>
    </div>
  )
}
