import { keyframes } from '@emotion/react'

const shadowAnimation = (customColor) => keyframes`
  0% {
    text-shadow: ${customColor} 0 0 10px;
    box-shadow: 0px 0px 20px 10px ${customColor};
  }
  50% {
    text-shadow: rgba(255, 255, 255, 0) 0 0 10px;
    box-shadow: 0px 0px 20px 10px rgba(255, 255, 255, 0);
  }
  100% {
    text-shadow: ${customColor} 0 0 10px;
    box-shadow: 0px 0px 20px 10px ${customColor};
  }
`

export const cardStyle = (customColor, backer) => {
  if (backer) {
    if (customColor) {
      return {
        animation: `${shadowAnimation(
          customColor,
        )} 5s infinite cubic-bezier(0.8, 0, 0.5, 1)`,
        boxShadow: `0px 0px 20px 10px ${customColor}`,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        maxWidth: 350,
      }
    }
  } else {
    return {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
      maxWidth: 350,
    }
  }
}
